export function formatCurrency(
  amount: number,
  {
    currency = 'USD',
    decimalPoints,
  }: { currency?: 'USD'; decimalPoints?: number } = {
    currency: 'USD',
    decimalPoints: 2,
  }
) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: decimalPoints,
    minimumFractionDigits: decimalPoints,
  })
  return formatter.format(amount)
}
